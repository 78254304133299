// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brochure-tsx": () => import("./../src/pages/brochure.tsx" /* webpackChunkName: "component---src-pages-brochure-tsx" */),
  "component---src-pages-cluster-dragon-tsx": () => import("./../src/pages/cluster-dragon.tsx" /* webpackChunkName: "component---src-pages-cluster-dragon-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nuevos-proyectos-tsx": () => import("./../src/pages/nuevos-proyectos.tsx" /* webpackChunkName: "component---src-pages-nuevos-proyectos-tsx" */),
  "component---src-pages-quienes-somos-tsx": () => import("./../src/pages/quienes-somos.tsx" /* webpackChunkName: "component---src-pages-quienes-somos-tsx" */)
}

